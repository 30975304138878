import React from 'react';

import VerificationInput from "react-verification-input";

import "./SPLanding.css";
import sweetPeaLogo from "./images/sweet-pea-logo.png";

class SPLanding extends React.Component {
    constructor(props) {
        super(props);
        this.code = "4122";
        this.state = {
            "characterClass": "vi-character",
            "code": "",
            "confetti": false,
            "styles": {
                "opacity": 1,
            },
        }
    }

    updateCodeState(code) {
        if (code === this.code) {
            this.props.onUnlock();
        } else if (code.length === 4) {
            this.setState({
                "characterClass": "vi-character-incorrect"
            })
        } else if (this.state.characterClass === "vi-character-incorrect") {
            this.setState({
                "characterClass": "vi-character"
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.hide != prevProps.hide) {
            this.setState({
                "styles": {
                    "opacity": 0,
                }
            })
        }
    }

    render() {
        return (
            <div className="sp-landing flex-container opacity-fade" style={this.state.styles}>
                <div className="flex-child logo" style={{marginBottom: "2em"}}>
                    <img src={sweetPeaLogo}></img>
                </div>
                <div className="flex-child input-container">
                    <VerificationInput
                        autoFocus
                        length={4}
                        validChars="0-9"
                        removeDefaultStyles
                        classNames={{
                            container: "vi-container",
                            character: this.state.characterClass,
                            characterInactive: "vi-character--inactive",
                            characterSelected: "vi-character--selected",
                        }}
                        onChange={this.updateCodeState.bind(this)}
                    />
                </div>
                <div style={{marginTop: "2em"}}>
                    <span className="text">Enter the passcode to unlock!</span>
                </div>
            </div>
        )
    }
}

export default SPLanding;