import React from 'react';

import Confetti from "react-confetti";

import "./SPConfetti.css";

var colours = {
    "gold": [
        '#FFDF00',
        '#D4AF37',
        '#CFB53B',
        '#C5B358',
        '#E6BE8A',
        '#996515'
    ],
    "officialGold": [
        '#FAFAD2',
        '#EEE8AA',
        '#F0E68C',
        '#DAA520',
        '#FFD700',
        '#FFA500',
        '#FF8C00',
        '#CD853F',
        '#D2691E',
        '#8B4513',
        '#A0522D'
    ],
}

class SPConfetti extends React.Component {
    render() {
        var w = window.innerWidth;
        var h = window.innerHeight;

        /*
         * We want to have the confetti spew over the same proportion of the window no matter its
         * size. This calculates the amount to scale the confetti velocity by relative to the window
         * size. Appropriate confetti velocity was empirically determined by manually tweaking
         * values.
         */
        var scaleX = w / 1500;
        var scaleY = h / 500;

        return (
            <div className="sp-confetti">
                <Confetti
                    confettiSource={{
                        x: this.props.left ? 0 : w,
                        y: h,
                        w: 1,
                        h: 1
                    }}
                    initialVelocityX={{
                        min: (this.props.left ? 5  : -20) * scaleX,
                        max: (this.props.left ? 20 : -5) * scaleX,
                    }}
                    initialVelocityY={{
                        min: -20 * scaleY,
                        max: -5 * scaleY,
                    }}
                    numberOfPieces={this.props.on ? 5000 : 0}
                    colors={colours.gold}
                />
            </div>
        )
    }
}

export default SPConfetti;