import React from 'react';

import './App.css';
import SPConfetti from './SPConfetti.jsx';
import SPLanding from './SPLanding.jsx';
import SPMain from './SPMain.jsx';

const pageStates = {
    "LANDING": 0,
    "CONFETTI": 1,
    "MAIN": 2,
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "confetti": false,
            "pageState": pageStates.LANDING,
        }
    }

    confettiBlast() {
        this.setState({
            "confetti": true,
            "pageState": pageStates.CONFETTI,
        });
        setTimeout(
            () => this.setState({
                "confetti": false,
                "pageState": pageStates.MAIN,
            }),
            3500
        );
    }

    render() {
        return (
            <div className="App">
                { this.state.pageState === pageStates.LANDING ||
                  this.state.pageState === pageStates.CONFETTI
                  ? <SPLanding
                        hide={this.state.pageState === pageStates.CONFETTI}
                        onUnlock={this.confettiBlast.bind(this)}
                    />
                  : undefined }
                <SPConfetti left on={this.state.confetti} />
                <SPConfetti right on={this.state.confetti} />
                { this.state.pageState === pageStates.CONFETTI ||
                  this.state.pageState === pageStates.MAIN
                    ?   <SPMain show={this.state.pageState === pageStates.CONFETTI || this.state.pageState === pageStates.MAIN} />
                    :   undefined }
            </div>
        );
    }
}

export default App;
