import React from 'react';

import "./SPMain.css";
import parents from "./images/parents.png";
import ourSweetPeaLogo from "./images/our-sweet-pea-logo.png";
import stalkWithPod from "./images/stalk-with-pod.png";
import sweetPea from "./images/sweet-pea.png";

class SPMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "styles": {
                "opacity": 0,
            },
        };
    }

    componentDidMount() {
        setTimeout(
            () => this.setState({
                "styles": {
                    "opacity": 1,
                }
            }),
            1
        );
    }

    render() {
        return (
            <div className="sp-main opacity-fade" style={this.state.styles}>
                <div className="our-logo">
                    <img src={ourSweetPeaLogo}></img>
                </div>
                <div className="parents">
                    <img src={parents}></img>
                </div>
                <div className="sweet-pea">
                    <img src={sweetPea}></img>
                    <span className="text">Sweet Pea will be coming out of its pod April 2022</span>
                </div>
                <div className="stalk">
                    <img className="left-bottom" src={stalkWithPod}></img>
                </div>
            </div>
        )
    }
}

export default SPMain;